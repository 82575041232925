@import "framework";

// Fonts

@import url('https://fonts.googleapis.com/css?family=Crimson+Text');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:700');

@font-face {
  font-family: 'social';
  font-weight: normal;
  font-style: normal;
  $filename: "/css/social/fontello";
  $i: "1382191";
  src: url("#{$filename}.eot?#{$i}");
  src: url("#{$filename}.eot?#{$i}#iefix") format("embedded-opentype"), url("#{$filename}.woff2?#{$i}") format("woff2"), url("#{$filename}.woff?#{$i}") format("woff"), url("#{$filename}.ttf?#{$i}") format("truetype"), url("#{$filename}.svg?#{$i}#fontello") format("svg");
}

// Vars

$body-size: 20px;
$ref-width: 1280px;
$body-gray: #9d9d9c;
$font-stack-minion: 'Minion Pro', 'Crimson Text', serif;
$font-stack-opensans: 'Open Sans Condensed', sans-serif;
$header-height-em: ((103.75px / $body-size) * 1em);

$mobile: (body-size: 30px, ref-width: 800px, breakpoint: 800px);

$header-height-mobile-em: ((148.19px / map-get($mobile,body-size)) * 1em);
// $square-container-vw: 100vw / 3 * 255 / 210; 
// 2/3 when portrait, 210/255 is magazine ratio
$square-container: 100vw / 3 * 255 / 210; 

// General

body {
  font-family: $font-stack-minion;
  @include set-body-size;
  @include portrait-or-mobile {
    @include set-body-size;
  }
}

a { color: inherit; text-decoration: none; @include transition(0.3s); }
a:hover { color: $body-gray; }

a.cta {
  padding: 0.5em 1.5em;
  border: 1px solid #000000;
  @include inline-block;
}

.condensed-title {
  font-family: $font-stack-opensans;
  font-size: em(24px); line-height: 1.4;
  text-transform: uppercase;
}

.gutters { padding: 0 0.5em; }

::-moz-selection {}
::selection {}

.page {
  overflow: hidden;
  min-width: 100%; min-height: 100%; min-height: 100vh;
}
.visible-page {
  overflow: hidden;
  width: 100%; min-height: 100%; min-height: calc(100vh - #{$header-height-em});
  &.animated {
    overflow: visible;
    height: 400%; height: calc((100vh - #{$header-height-em}) * 4);
  }
  @include portrait-or-mobile {
    min-height: calc(100vh - #{$header-height-mobile-em});
    &.animated {
      height: calc((100vh - #{$header-height-mobile-em}) * 4);
    }
  }
}

.centered {
  position: absolute; left: 50%; top: 50%;
  @include transform(translate(-50%,-50%));
}

.video-wrap {
  @include fixed-proportions-wrap(640,360);
}

form {
  input, button, textarea {
    margin: 0; padding: 0; border: 0;
    border-bottom: 1px solid $body-gray;
    background-color: transparent;
    font-family: $font-stack-minion;
    font-size: 1em;
    color: $body-gray;
  }
  textarea {
    height: 5em; resize: none;
  }
  input:focus, textarea:focus { outline: none; }
  button { cursor: pointer; }
  input[type=submit] {
    text-transform: uppercase;
    border: 1px solid $body-gray;
    padding: 0.5em 2em;
    cursor: pointer;
  }
  input[type=submit]:hover { color: #ffffff; background-color: $body-gray; }
}

// Swipers

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: none;
  width: 50%; height: 100%; left: 0; top: 0; margin: 0; padding: 0;
}
.swiper-button-prev:hover, .swiper-container-rtl .swiper-button-next:hover {
  cursor: url(/img/arrow-black-left.png) 40 40, auto;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: none;
  width: 50%; height: 100%; right: 0; top: 0; margin: 0; padding: 0;
}
.swiper-button-next:hover, .swiper-container-rtl .swiper-button-prev:hover {
  cursor: url(/img/arrow-black-right.png) 40 40, auto;
}

// Splash

.splash {
  @extend .page;
  z-index: 200;
  position: fixed; left: 0; top: 0;
  img {
    position: absolute; left: 50%; top: 50%;
    @include transform(translate(-50%,-50%));
    width: 50%;
  }
}

// Header

body > header {
  $anim: 0.4s ease-in-out;
  position: fixed; left: 0; top: 0;
  z-index: 100;
  width: 100%;
  text-align: center;
  @include transition($anim);

  &.hidden { @include transform(translateY(-100%)); }
  &.compact {
    nav {
      @include transform(translateY(-100%));
      pointer-events: none;
    }
  }

  .logo-bar {
    border-bottom: 1px solid #000000;
    padding-top: em(15px); padding-bottom: em(15px);
    background-color: #ffffff;
    position: relative;
    z-index: 102;
    .logo-wrap {
      display: block; margin: 0 auto;
      width: (100% / 12 * 2);
      width: (100vw / 12 * 2);
      // @include opacity(1);
      @include transition($anim);
      @include portrait-or-mobile {
        width: (100% / 12 * 4);
        width: (100vw / 12 * 4);
      }
    }
  }

  nav {
    @include transition($anim);
    background-color: #ffffff;
    z-index: 101;
    .menu-bar, .submenu-bar {
      padding-top: em(15px); padding-bottom: em(15px);
      ol {
        line-height: em(16px);
        height: em(16px);
        li {
          display: inline;
          margin-left: em(15px); margin-right: em(15px);
          a {
            font-size: em(16px);
            text-transform: uppercase;
          }
        }
      }
    }
    .menu-bar {
      border-bottom: 1px solid #000000;
    }
  }

}

// Content

.content { z-index: 2; }

.content:not(.late-header) {
  margin-top: $header-height-em;
  @include portrait-or-mobile {
    margin-top: $header-height-mobile-em;
  }
}

// Features List

.splash-panel {
  @extend .page;
  color: #ffffff;
  position: fixed;
  z-index: 1;
  .centered {
    text-align: center;
    width: 100%;
    header, footer {
      font-size: em(25px); line-height: 1.2;
      font-size: 2.5vh; letter-spacing: 0.25em;
      text-transform: uppercase;
    }
    header { margin-bottom: 3em; margin-bottom: 6vh; }
    footer { margin-top: 3em; margin-top: 6vh; }
    .body-copy {
      font-size: em(30px); line-height: 1.2;
      font-size: 5vh;
      @media all and (orientation:portrait) {
        font-size: 3vh;
      }
    }
  }
}

.before-header { pointer-events: none; }
.before-header, .after-header { z-index: 2; position: relative; }
.after-header { background-color: #ffffff; }

// Splash image

.splash-image {
  @extend .page;
  position: relative;
  img {
    @extend .centered;
    pointer-events: auto;
    width: auto; height: 50%; height: 80vh;
    @media all and (orientation:portrait) {
      height: 60vh;
    }
  }
}

// Homepage intro

.home-intro {
  // background-color: #e43a27;
  background-color: #a4a3a4;
  padding: 0 (100% / 12);
  & > div {
    background-color: #f6f6f6;
    text-align: center;
    padding: em(130px) (100% / 8);
    header h1 {
      font-size: em(25px); letter-spacing: 0.25em; font-weight: normal;
      text-transform: uppercase;
    }
    div {
      font-size: em(32px); line-height: 1.4;
    }
  }
}

// Home past present

.home-past-present {
  background-color: #ececec;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  .name, .text {
    @extend .centered;
    width: 100%; text-align: center;
  }
  .name {
    // color: #e43a27;
    font-size: em(144px); line-height: 1.4;
    font-family: $font-stack-opensans;
  }
  .text {
    font-size: em(32px); line-height: 1.4;
    padding: 0 (100% / 6);
  }
  img {
    position: relative;
    @include opacity(1);
    @include transition(0.2s);
    &:hover {
      @include opacity(0);
    }
  }
}

// Home swiper

.home-swiper {
  @extend .visible-page;
  position: relative;
  background-color: #f6f6f6;
  @include portrait-or-mobile {
    min-height: 0;
  }
  .swiper-container {
    @extend .centered;
    @include portrait-or-mobile {
      position: static;
      @include transform(translate(0,0));
    }
    width: (100% / 12 * 8);
    @include portrait-or-mobile {
      width: 100%;
    }
  }
}

// Circular project

.circular-project-slideshow {
  @include fixed-proportions-wrap(1280,700);
  background-image: url(/img/circular-bg.jpg);
  background-position: center center;
  background-size: cover;
  .slides {
    img {
      position: absolute; left: 50%; top: 50%;
      @include transform(translate(-50%,-50%));
      @include vendor(clip-path, circle(0%));
      @include transition(0.5s ease-in-out);
      // background-color: rgba(255,255,255,0.2);
    }
    img.active {
      @include vendor(clip-path, circle(44%));
    }
    img:first-child {
      // @include transition(0.4s ease-in-out);
    }
    img:first-child.active {
      @include vendor(clip-path, circle(15%));
    }
  }
  .prev, .next {
    position: absolute; left: 0; top: 0;
    width: 50%; height: 100%;
    cursor: pointer;
  }
  .next {
    left: 50%;
  }
  .prev:hover { cursor: url(/img/arrow-white-left.png) 40 40, auto; }
  .next:hover { cursor: url(/img/arrow-white-right.png) 40 40, auto; }
}

// Last issue swiper

.last-issue-swiper {
  @extend .visible-page;
  position: relative;
  background-color: #a4a3a4;
  text-align: center;
  .centered {
    width: (100% / 12 * 7);
    @media all and (orientation:portrait) {
      width: (100% / 12 * 10);
    }
    header, footer {
      color: #ffffff;
      font-size: em(20px); letter-spacing: 0.25em; line-height: 1.4;
      text-transform: uppercase;
      // @extend .condensed-title;
      @media all and (orientation:portrait) {
        font-size: em(40px);
      }
    }
    header { margin-bottom: em(24px); }
    footer { 
      margin-top: em(24px); 
      a:hover { color: #ffffff; } 
    }
    .swiper-container {
      .swiper-slide[data-swiper-slide-index="0"] {
        img { padding: 0 25%; }
      }
    }
  }
}

// Editions

section.editions {
  header {
    padding-top: em(100px); padding-bottom: em(100px);
    text-align: center;
    h1 {
      font-size: em(20px); letter-spacing: 0.25em; line-height: 1.4;
      text-transform: uppercase; font-weight: normal;
      // @extend .condensed-title;
    }
  }
  ol.issues {
    @extend .inline-block-container;
    padding-left: (100% / 6); padding-right: (100% / 6);
    padding-bottom: em(50px);
    @include portrait-or-mobile {
      padding-left: (100% / 12); padding-right: (100% / 12);
    }
    li {
      @extend .gutters;
      width: 25%;
      @include portrait-or-mobile {
        width: 50%;
      }
      text-align: center;
      padding-bottom: em(50px);
      img { border: 1px solid $body-gray; }
      a:hover img { border: 1px solid #000000; }
      h2 {
        margin: 1em 0 0 0;
        font-size: 1em; font-weight: normal;
      }
      a, span.state-name { color: $body-gray; }
      a:hover { color: #000000; }
    }
  }
}

// Introduction

.intro {
  @extend .home-intro;
  background-color: #000000;
}
.intro-swiper {
  @extend .home-swiper;
  background-color: #a4a3a4;
}

// Media kit

.kit-heading {
  padding: em(90px) 0; margin: 0 auto; width: 50%;
  text-align: center;
  @include portrait-or-mobile {
    width: (100% / 12 * 10);
  }
  h2, h3 {
    font-weight: normal; letter-spacing: 0.1em;
    margin: 0; text-transform: uppercase;
  }
  h2 {
    font-size: em(12px); line-height: 4.16;
  }
  h3 {
    font-family: $font-stack-opensans;
    font-size: em(40px); line-height: 1.25;
  }
  div { margin-top: 2em; font-size: em(16px); }
}

.kit-header {
  position: relative;
  .over {
    width: (100% / 12 * 5);
    position: absolute; left: (100% / 12); top: 50%;
    @include transform(translate(0,-50%));
    text-align: center; color: #ffffff;
    .year {
      font-family: $font-stack-opensans;
      font-size: em(24px); line-height: 1.4;
      @include portrait-or-mobile {
        font-size: em(12px);
      }
    }
    h1 {
      font-size: em(64px); line-height: 1.25;
      font-weight: normal; margin: 0;
      @include portrait-or-mobile {
        font-size: em(32px);
      }
    }
  }
}

.kit-intro {
  padding: 0 (100% /12);
  .main {
    @extend .inline-block-container;
    & > * { 
      width: 50%; padding: 0 0.5em 0 0.5em; font-size: em(16px); 
      @include portrait-or-mobile {
        width: 100%; padding-bottom: 1em;
      }
    }
    & > *:first-child {
      padding-right: 7em;
      @include portrait-or-mobile {
        padding-right: 0.5em;
      }
    }
  }
  ol {
    @extend .inline-block-container;
    & > * { 
      width: 25%; padding: em(50px) 0.5em 0 0.5em; 
      @include portrait-or-mobile {
        width: 50%;
      }
      img { border: 1px solid $body-gray; } 
    }
  }
}

.kit-demographic {
  padding: em(100px) (100% / 12) 0 (100% /12);
  .inline-block-container {
    .left, .right { padding: 0 0.5em; }
    .left { 
      width: (100% / 10 * 3); 
      @include portrait-or-mobile {
        width: 100%; margin-bottom: em(100px);
      }
      table {
        font-size: em(16px);
        width: 100%;
        border-collapse: collapse;
        tr td { border-bottom: 1px solid #000000; }
        tr.high td { padding-top: 1em; }
        tr:last-child td { border: 0; }
      }
    }
    .right { 
      width: (100% / 10 * 7); 
      @include portrait-or-mobile {
        width: 100%;
      }
      text-align: center;
      b { 
        font-family: $font-stack-opensans; font-weight: normal; 
        text-transform: uppercase;
      }
      table { 
        font-size: em(16px);
        width: 100%;
        text-transform: uppercase; 
        @include portrait-or-mobile {
          font-size: em(12px);
        }
        tr.images {
          img { width: 60%; margin: 4em auto; }
        }
      }
    }
  }
}

.kit-sample {
  padding: em(100px) (100% / 12) 0 (100% /12);
  .swiper-container { border: 1px solid $body-gray; }
}

.kit-bespoke {
  padding: em(100px) (100% / 12) 0 (100% /12);
  section { 
    margin-bottom: em(100px); 
    text-align: center;
    h4 { 
      font-weight: normal; text-transform: uppercase;
      font-family: $font-stack-opensans; 
      font-size: em(24px); line-height: 1.0416;
    }
    .editorial-image { padding: 0 0.5em; }
    .videos {
      & > * { 
        width: 50%; padding: 0 0.5em; 
        @include portrait-or-mobile {
          width: 100%; margin-bottom: 2em;
        }
      }
    }
  }
}

.kit-advertising {
  padding: em(100px) (100% / 12) 0 (100% /12);
  .deadlines, .rates {
    width: 50%; text-align: center; padding: 0 0.5em;
    b { font-family: $font-stack-opensans; text-transform: uppercase; }
    small { font-size: em(12px); }
    @include portrait-or-mobile {
      width: 100%;
    }
  }
  .rates { 
    border-left: 1px solid #000000; 
    @include portrait-or-mobile {
      border: 0; margin-top: 3em;
    }
  }
}

.kit-contact {
  form {
    @extend .inline-block-container;
    width: 50%; margin: 0 auto;
    @include portrait-or-mobile {
      width: (100% / 12 * 10);
    }
    & > * {
      width: 100%; padding: 0 0.5em;
      margin-bottom: 1.5em;
      input[type=text], input[type=email], textarea {
        width: 100%; 
      }
    }
    & > *:nth-child(1), & > *:nth-child(2) { width: 50%; }
  }
}

// Authenticate

.authenticate-panel {
  @extend .page;
  position: fixed; left: 0; top: 0; z-index: 200;
  background-color: #f6f6f6;
  div { font-size: em(12px); text-transform: uppercase; margin-bottom: 1.5em; }
}


// Journal

.journal-article-video {
  padding: em(60px) 1em;
}

.journal-intro {
  @extend .strip-margins-container;
  padding-top: em(60px + 46px);
  padding-left: 0.5em; padding-right: 0.5em;
  width: 50%;
  margin-left: auto; margin-right: auto;
  text-align: center;
  @include portrait-or-mobile {
    padding-top: em(60px + 69px);
    width: 100%;
  }
  p {
    font-size: em(32px);
    padding-left: em(10px, 32px); padding-right: em(10px, 32px);
  }
}

ol.journal-articles {
  margin-top: em(30px);
  li {
    @include inline-block;
    vertical-align: top;
    width: 30%;
    margin-bottom: em(30px);
    @include portrait-or-mobile {
      width: 50%;
    }
    .series-name {
      font-size: em(10px); line-height: 1;
      margin: em(15px, 10px) 0;
      text-transform: uppercase;
    }
    .title {
      font-size: em(15px); line-height: 1;
    }
    &:nth-child(6n+1), &:nth-child(6n+6) { 
      width: 40%; 
      @include portrait-or-mobile {
        width: 100%;
      }
    }
  }
}

// Journal Article

.journal-article-fullpage {
  @extend .visible-page;
  border-bottom: 1px solid #000000;
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto;
  grid-template-areas: "header illustration" "footer illustration";
  @include portrait-or-mobile {
    min-height: 0;
    // display: block;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "header" "illustration" "footer";
  }
  header {
    grid-area: header;
    padding: em(60px) 1em;
    .series-name {
      font-size: em(15px);
      text-transform: uppercase;
      margin-bottom: em(15px, 15px);
    }
    .title {
      font-size: em(40px);
      margin-bottom: em(30px, 40px);
    }
    .text {
      @extend .strip-margins-container;
      .cta { margin-top: 1em; }
    }
  }
  .illustration {
    grid-area: illustration;
    position: relative;
    width: 100%; height: auto;
    @include portrait-or-mobile {
      height: 70vh;
    }
    .wrap {
      width: (100% / 3 * 2);
      position: absolute;
      left: 50%;
      top: 50%;
      @include transform(translate(-50%,-50%));
      &.landscape { width: 80%; }
      &.anchor-top-left { background-position: left top; }
      &.anchor-top-center { background-position: center top; }
      &.anchor-top-right { background-position: right top; }
      &.anchor-center-left { background-position: left center; }
      &.anchor-center-center { background-position: center center; }
      &.anchor-center-right { background-position: right center; }
      &.anchor-bottom-left { background-position: left bottom; }
      &.anchor-bottom-center { background-position: center bottom; }
      &.anchor-bottom-right { background-position: right bottom; }
    }
  }
  footer {
    grid-area: footer;
    align-self: end;
    padding: em(60px) 1em;
    .credit {
      font-size: em(15px);
    }
  }
  &.animated {
    $transition: 1s;
    display: block;
    header {
      position: -webkit-sticky;
      position: sticky;
      top: $header-height-em;
      // height: calc(100vh - #{$header-height-em});
      width: 50%;
      height: 25%;
      @include portrait-or-mobile {
        width: 100%;
      }
      .text {
        @include opacity(0);
        @include transition($transition);
      }
    }
    footer {
      position: absolute;
      bottom: em(60px);
      width: 50%;
      @include portrait-or-mobile {
        position: absolute;
        width: 100%;
      }
    }
    .illustration {
      position: -webkit-sticky;
      position: sticky;
      top: $header-height-em;
      width: 50%;
      height: 25%;
      margin-left: 25%;
      margin-bottom: -50vh;
      @include transition($transition);
      @include portrait-or-mobile {
        margin-left: 0;
        width: 100%;
      }
      .wrap {
        background-size: 300%; 
        @include transition($transition);
        img {
          @include opacity(0);
        }
      }
    }
    &[data-animation-phase="0"] {
      .illustration { width: 100%; margin-left: 0; }
      .illustration .wrap { width: 100%; min-height: 100vh; }
    }
    &[data-animation-phase="1"] {
      .illustration .wrap { background-size: 100%; min-height: 0; }
    }
    &[data-animation-phase="2"] {
      header .text { @include opacity(1); }
      .illustration { @include opacity(0); }
      .illustration .wrap { background-size: 100%; }
    }
  }
  &.journal-article-product, &.journal-article-issue {
    header .series-name {
      margin-bottom: em(30px, 15px);
    }
  }
}

.journal-article-pre-sections, .journal-article-post-sections {
  .section {
    width: 100%;
    border-bottom: 1px solid #000000;
    // min-height: calc(100% - #{$header-height-em});
    // min-height: calc(100vh - #{$header-height-em});
    @include flexbox;
    // align-items: center;
    align-items: baseline;
    justify-content: center;
    .spread {
      @include inline-block;
      vertical-align: top;
      width: 50%;
      min-height: 100%;
      min-height: calc(100% - #{$header-height-em});
      min-height: calc(100vh - #{$header-height-em});
      padding-top: em(60px); padding-bottom: em(60px);
      position: relative;
      @include flexbox;
      align-items: center;
      justify-content: center;
      &.single {
        width: 100%;
      }
      @include portrait-or-mobile {
        width: 100%;
        min-height: 0;
      }
      .image-and-caption {
        width: (100% / 3 * 2);
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // @include transform(translate(-50%,-50%));
        &.landscape { width: 80%; }
        .caption {
          font-size: em(15px);
          font-size: 0.6em;
          font-style: italic;
        }
      }
    }
  }
}

.journal-article-sections {
  border-bottom: 1px solid #000000;
  .section-anchor {
    width: 100%;
    position: relative;
    .illustration, .text {
      width: 50%;
      @include inline-block;
      vertical-align: top;
      background-color: #ffffff;
      @include portrait-or-mobile {
        width: 100%;
      }
    }
    .illustration {
      position: -webkit-sticky;
      position: sticky;
      height: 0;
      top: $header-height-em;
      left: 0;
      @include opacity(1);
      @include transition(0.2s);
      &:last-of-type {
        height: auto;
      }
      &.faded {
        @include opacity(0);
        @include portrait-or-mobile {
          @include opacity(1);
        }
      }
      @include portrait-or-mobile {
        position: static;
        height: auto;
        margin-top: em(60px); margin-bottom: em(60px);
      }
      .wrap {
        height: calc(100% - #{$header-height-em});
        height: calc(100vh - #{$header-height-em});
        position: relative;
        @include portrait-or-mobile {
          height: auto;
        }
        img {
          width: 80%; height: 80%;
          width: $square-container; height: $square-container;
          object-fit: contain;
          position: absolute;
          left: 50%;
          top: 50%;
          @include transform(translate(-50%,-50%));
          @include portrait-or-mobile {
            position: static;
            @include transform(translate(0%,0%));
            margin-left: auto; margin-right: auto;
            width: ($square-container * 2); height: ($square-container * 2);
            height: auto;
          }
        }
        .caption {
          position: absolute;
          left: 0; bottom: 0;
          width: 100%;
          text-align: center;
          padding: 1em 20%;
          font-style: italic;
          font-size: 0.6em;
          @include portrait-or-mobile {
            position: static;
            padding: 1em 10% 0 10%;
          }
        }
      }
    }
    .text {
      padding-top: 0.5em; padding-bottom: 0.5em;
      @include portrait-or-mobile {
        padding-top: 0; padding-bottom: 0;
      }
      &:first-of-type {
        padding-top: em(60px); 
        @include portrait-or-mobile {
          padding-top: 0;
        }
      }
      &:last-of-type {
        padding-bottom: em(60px);
      }
      .paragraphs {
        @extend .strip-margins-container;
      }
    }
  }
}

// Error pages

.error-page {
  padding: em(100px) (100% / 12);
  h1, h2 { font-weight: normal; }
}

// Footer

.advert { 
  padding-top: 4em;
  background-color: #ffffff;
  position: relative;
  z-index: 2;
}

body > footer {
  background-color: #ffffff;
  border-top: 1px solid #000000;
  padding: 2em (100% / 12);
  padding: 2em (100vw / 12);
  position: relative;
  z-index: 2;
  .inline-block-container {
    > div:first-child, > div:nth-child(2) {
      width: (100% / 10 * 3);
      form {
        input { width: 13em; }
      }
    }
    > div:last-child {
      width: (100% / 10 * 4);
      text-align: right;
      font-family: 'social', serif;
      letter-spacing: 0.5em;
    }
    @include portrait-or-mobile {
      > div:first-child, > div:last-child { width: 50%; }
      > div:last-child { width: 50%; font-size: 1.5em; }
      > div:nth-child(2) { display: none; }
    }
  }
}

